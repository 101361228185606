import { BrowserFeature } from '../feature-flag/browser-feature';
import { CameraSource, ImageSource, MicrophoneSource, ScreenSource, TextSource } from './go-source';
import { Destination, DestinationType } from './destinations/destination';
import { DeviceKit } from 'go-modules/device-kit';
import { FeatureSupport } from 'go-modules/feature-support/feature-support';
import { FULLSTORY_EVENTS } from 'go-modules/services/fullstory/fullstory.events';
import { GoScene } from './go-scene/go-scene';
import { GoSource, GoSourceConfig } from './go-source/go-source';
import { IAugmentedJQuery, ILogService, IScope, noop } from 'angular';
import { MediaStreamFactory } from 'go-modules/device-kit/media-stream-factory/media-stream-factory';
import { OpentokDestination, SUBSCRIBER_CHANGE } from './destinations/opentok-destination/opentok.destination';
import { Reasons, StateEmitter, States } from './state-emitter/state-emitter';
import { RecordingStoppedState } from './recording-stopped-state';
import { StatusOverlayReason } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay-reason';
import { Timer } from '../timer/timer';
import silentAudioFile from './whitenoise.mp3';
import { UADetect as UADetectClass } from 'go-modules/detect/ua-detect.service';
import type {
	UnsavedDataRegistration
} from 'ngx/go-modules/src/services/unsaved-data-manager/unsaved-data-manager.service';
import {
	NgxUnsavedDataManagerService
} from 'ngx/go-modules/src/services/unsaved-data-manager/unsaved-data-manager.service';
import { SESSION_RESTART, VideoSceneOptions } from './video-scene.options';
import { FullstoryService } from 'go-modules/services/fullstory/fullstory.service';
import { StatusOverlayable } from 'ngx/go-modules/src/components/video-scene/status-overlay/status-overlay.component';
import {
	CONTINUE_WITHOUT_AUDIO_KEY,
	MUTE_INDICATOR_POSITIONS,
	RECORDING_BLUR_START_ACTIVE_KEY,
	RECORDING_CAM_START_ACTIVE_KEY,
	RECORDING_IS_MIRRORED,
	RECORDING_MIC_START_ACTIVE_KEY
} from 'go-modules/video-scene/constants';
import type { MessageModal } from 'go-modules/modals/message/modal.factory';
import type { MessageRememberingModal } from 'go-modules/modals/message-remembering/modal.factory';
import { AuthService } from 'go-modules/services/auth/auth.service';
import { TrapIndexConfig, TrapTabIndexService } from 'go-modules/trap-tab-index/trap-tab-index.service';
import { SegmentationStates } from './go-source/camera-source/background-blur/background-blur';
import { NgxGoToastService } from 'ngx/go-modules/src/services/go-toast/go-toast.service';
import { GoToastStatusType } from 'ngx/go-modules/src/enums/go-toast-status-type';
import { MediaRecorderDestination } from './destinations/media-recorder-destination/media-recorder.destination';
import { UploadManagerService } from 'go-modules/services/upload-manager/upload-manager.service';
import { MediaStreamSource } from './go-source/media-stream-source';
import { FeatureFlag } from 'go-modules/feature-flag/feature-flag.service';
import { DowngradeModalService } from 'ngx/go-modules/src/services/downgrade-modal/downgrade-modal.service';
import { VideoQuality, VideoQualityUtil } from 'ngx/go-modules/src/utilities/video-quality/video-quality.util';
import { SelectedService } from 'go-modules/services/selected/selected.service';
import { EventService } from 'ngx/go-modules/src/services/event/event.service';
import { Subject, takeUntil } from 'rxjs';
import { EVENT_NAMES } from 'ngx/go-modules/src/services/event/event-names.constants';
import { Configuration, NewSessionData, StreamingAvatarApi } from '@heygen/streaming-avatar';
import { RealTimeCaptionsService } from 'go-modules/captions/real-time-captions.service';
import type { SessionManagerService } from 'go-modules/session-manager/session-manager.service';
import type { RealtimeTranscript } from 'assemblyai';

export type OnInitFunction = (context: { recorder: VideoSceneController }) => void;

interface Bindings {
	options: VideoSceneOptions;
	onInit: OnInitFunction;
	onConnectionStatus: ({goodConnection}) => void;
	disabled: boolean;
}

const defaultOptions = {
	autoResumeAfterDisconnect: true,
	stateLabels: {
		uninitialized: 'media-recorder_controller-record',  // Record (disabled)
		initialized: 'media-recorder_controller-record',  // Record (disabled)
		active: 'media-recorder_controller-record',      // Record
		starting: 'media-recorder_controller-pause',    // Pause (disabled)
		recording: 'media-recorder_controller-pause',   // Pause
		pausing: 'media-recorder_controller-resume',    // Resume (disabled)
		paused: 'media-recorder_controller-resume',     // Resume
		stopping: 'common_stop',   // Record (disabled)
		stopped: 'common_stop'     // Record (page navigate)
	},
	timeLimit: null,
	connectionTimeout: 0 // Indefinite
};

const disabledStates: string[] = [
	States.UNINITIALIZED,
	States.INITIALIZING,
	States.STARTING,
	States.PAUSING,
	States.STOPPING,
	States.STOPPED,
	States.FAILED,
	States.DESTROYED,
	States.MISSING
];

const pausedStates: string[] = [
	States.INITIALIZING,
	States.ACTIVE,
	States.STOPPED,
	States.STOPPING
];

const disabledDestinationStates: string[] = [].concat(disabledStates, [ States.INACTIVE ]);

export class VideoSceneController extends StateEmitter implements Bindings, ng.IController {
	// Bindings
	public disabled: boolean;
	public hideControls: boolean; // Not sure if we ever hide controls any more
	public hideTimeDisplay: boolean;
	public onInit: OnInitFunction;
	public onConnectionStatus: ({goodConnection}) => void;
	public connectionInterval;
	public umcInstanceName: string;

	// Public Values
	public aspectRatio: string = '4:3';
	public isMirrored: boolean = true;
	public cameraEnabled: boolean = true;
	public screenCaptureEnabled: boolean = false;
	public options: VideoSceneOptions;
	public timer: Timer = new Timer();
	public deviceKit: DeviceKit = new DeviceKit(navigator.mediaDevices, navigator.permissions);
	public statusOverlay: StatusOverlayable = null;
	public isRecording: boolean = false;
	public showMuteWarning: boolean = false;
	public captionsStream: MediaStream = null;
	public captionsActive: boolean = false;
	public menuActive: boolean = false;
	public mediaSettingsActive: boolean = false;
	public subscriberCount: number = 0;
	public packetsLost: number = 0;
	public packetsSent: number = 0;

	// These will be revamped once we don't have a hard mic and cam select
	public cameras: MediaStreamFactory[];
	public selectedCameraIds: string[] = [];
	public microphones: MediaStreamFactory[];
	public selectedMicrophoneId: string = null;
	public muteIndicator: ImageSource;
	public silhouetteSource: ImageSource;
	public displayNameTextSource: TextSource;
	public mediaSettingsTabGroup;
	protected recordingStoppedState: RecordingStoppedState = RecordingStoppedState.STOPPED_BY_SOMEONE_ELSE;

	// Private Values
	private scene: GoScene;
	private destinations: Destination[] = [];
	private keepAliveEle: HTMLAudioElement;
	private outputContainer: HTMLElement;
	private unsavedDataRegistration: UnsavedDataRegistration;
	private sourceConfig: GoSourceConfig;
	private componentDestroyed$$ = new Subject();

	// HeyGen
	public heyGenScenario: string = 'interview';
	private avatar: StreamingAvatarApi;
	private avatarData: NewSessionData;
	private speechToText: RealTimeCaptionsService = null;
	private speechToTextInitialized = false;
	private heygenEchoing = false;
	private conversation = '';
	private interrupted;
	private cancelDeferred;

	/* @ngInject */
	constructor (
		private $analytics,
		private $document: ng.IDocumentService,
		private $element: IAugmentedJQuery,
		private $interval: ng.IIntervalService,
		private $log: ILogService,
		private $q: ng.IQService,
		private $scope: IScope,
		private $timeout: ng.ITimeoutService,
		private $window: ng.IWindowService,
		private authService: AuthService,
		private featureSupport: FeatureSupport,
		private fullstoryService: FullstoryService,
		private GoGlobalEvent,
		private GoReactHeartbeatEvent,
		private ngxGoToastService: NgxGoToastService,
		private messageModal: MessageModal,
		private messageRememberingModal: MessageRememberingModal,
		public featureFlag: FeatureFlag,
		private TrapTabIndex: TrapTabIndexService,
		private translateFilter,
		private UADetect: UADetectClass,
		private ngxUnsavedDataManagerService: NgxUnsavedDataManagerService,
		private uploadManager: UploadManagerService,
		private $mdLiveAnnouncer,
		private ngxDowngradeModalService: DowngradeModalService,
		private $translate: ng.translate.ITranslateService,
		private selectedService: SelectedService,
		private eventService: EventService,
		// Hey Gen
		private $http: ng.IHttpService,
		private sessionManager: SessionManagerService
	) {
		super();
	}

	public $onInit () {
		this.eventService.listen([
			EVENT_NAMES.VIDEO_SCENE_RESTART_SESSION,
			EVENT_NAMES.VIDEO_SCENE_STOP_SESSION
		])
			.pipe(takeUntil(this.componentDestroyed$$))
			.subscribe((ev) => {
				switch(ev.name) {
					case EVENT_NAMES.VIDEO_SCENE_RESTART_SESSION:
						this.setStatusOverlay(new StatusOverlayReason(Reasons.RESTARTING_SESSION));
						break;
					case EVENT_NAMES.VIDEO_SCENE_STOP_SESSION:
						this.stop()
							.then(() => {
								if(ev.data?.callback) {
									ev.data.callback();
								}
							});
				}
			});

		this.scene = new GoScene(undefined, undefined);
		this.aspectRatio = this.scene.getAspectRatio();
		this.on(StateEmitter.EVENTS.STATE_CHANGE, this.handleStateChange);
		this.options = { ...defaultOptions, ...this.options };
		const flags = this.featureFlag.getSynchronizedFlags();
		if (flags.length) {
			this.options.params = this.options.params || {};
			this.options.params.feature_flags = flags.join(',');
		}
		this.options.opentokHack = this.featureFlag.isAvailable('OPENTOK_HACK');
		this.timer.on(Timer.EVENT.TIME, this.timeSync);
		this.keepAlive();
		if (this.options.warnOnExit) this.initUnsavedDataRegister();
		this.warnNoScreenCaptureIfFirefoxSafari();
		this.onInit({ recorder: this });
		this.$document.on('keydown', this.escapeListener.bind(this));

		if (this.heyGenEnabled()) {
			this.speechToText = new RealTimeCaptionsService();
			this.speechToText.setAccessToken(this.sessionManager.getAccessToken());
		}
	}

	public $postLink () {
		this.sourceConfig = {
			appendElement: this.$element[0].querySelector('.source-container')
		};
		this.outputContainer = this.$element[0].querySelector('.output-container');
		this.$document[0].body.addEventListener('click', this.closeMenus);
		this.checkUnsupportedBrowser();
		this.setup();
	}

	public $onDestroy (): void {
		this.componentDestroyed$$.next(true);
		this.componentDestroyed$$.complete();
		const screenSource = this.getScreenSource();

		this.timer.destroy();
		if (this.unsavedDataRegistration) {
			this.ngxUnsavedDataManagerService.unregister(this.unsavedDataRegistration);
		}
		this.$window.document.removeEventListener('visibilitychange', this.handleVisibilityChange);
		if (screenSource) {
			screenSource.off(GoSource.EVENTS.STATE_CHANGE, this.sourceStateChange);
			const track = screenSource.stream.getVideoTracks()[0];
			track.removeEventListener('ended', this.onScreenCaptureEnd);
			this.scene.removeSource(screenSource);
		}
		this.destinations.forEach((destination) => destination.destroy());
		if (this.keepAliveEle) {
			this.keepAliveEle.src = '';
			this.keepAliveEle = null;
		}
		this.scene.destroy();
		this.$document[0].body.removeEventListener('click', this.closeMenus);
		if (this.captionsStream) {
			this.captionsStream.getTracks().forEach((track) => track.stop());
			this.captionsStream = null;
		}
		this.$document.off('keydown', this.escapeListener.bind(this));
		super.destroy();
		this.$interval.cancel(this.connectionInterval);
	}

	// DESTINATION CONTROLS
	public async stop (): Promise<void> {
		this.$mdLiveAnnouncer.announce(this.translateFilter('recording-stopped'));
		this.setState(States.STOPPING);
		this.recordingStoppedState = RecordingStoppedState.STOPPED_BY_ME;
		const promiseArr = this.destinations.map((destination) => destination.stop());
		await Promise.all(promiseArr);
	}

	public confirm () {
		if (this.subscriberCount > 1) {
			this.ngxDowngradeModalService.openConfirmDialog({
				title: this.$translate.instant('media-recorder_controller-stop-recording'),
				message: this.$translate.instant('media-recorder_controller-stop-recording-message'),
				confirmText: this.$translate.instant('media-recorder_controller-stop-recording')
			}).then(() => {
				this.stop();
			}).catch(() => noop);
		} else {
			this.stop();
		}
	}

	public async startRecorder (): Promise<void> {
		const previousState = this.state;
		const key = (previousState === States.ACTIVE) ? 'recording-started' : 'recording-resumed';
		this.$mdLiveAnnouncer.announce(this.translateFilter(key));
		this.setState(States.STARTING);
		const promiseArr = this.destinations.map((destination) => destination.start());
		await Promise.all(promiseArr);
	}

	public async pauseRecorder (): Promise<void> {
		this.$mdLiveAnnouncer.announce(this.translateFilter('recording-paused'));
		this.setState(States.PAUSING);
		const promiseArr = this.destinations.map((destination) => destination.pause());
		await Promise.all(promiseArr);
	}

	public async toggleRecord (): Promise<void> {
		try {
			switch (this.state) {
				case States.ACTIVE:
				case States.PAUSED:
					await this.startRecorder();
					break;
				case States.RECORDING:
					await this.pauseRecorder();
					break;
			}
		} catch (error) {
			this.$log.error(error);
		}
	}

	public async toggleScreenCapture (): Promise<void> {
		if (!this.getScreenSource()) {
			this.isMirrored = false;
			const screenSource = this.createSource(new ScreenSource(this.sourceConfig));
			try {
				await screenSource.init();
				this.$mdLiveAnnouncer.announce(this.translateFilter('screen-capture-enabled'));
			} catch (e) {
				// The user cancelled screensharing or it otherwise failed
				this.$log.warn('Screen Share Failed', e);
				this.scene.removeSource(screenSource);
				if (e?.name === 'SecurityError') {
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'chrome-iframe-screenshare-issue'
					});
				}
				return;
			}

			const track = screenSource.stream.getVideoTracks()[0];

			// Add event listener for browsers that provide a system-level button to exit screen capture
			track.addEventListener('ended', this.onScreenCaptureEnd);
			this.determineMediaSourcesLayout(this.getCameraSource().active);
			this.$scope.$digest();
			this.trackScreenCaptureEvent('enter-screen-capture');

			// Send message that screen capture has started
			await this.onScreenCaptureStart();
			this.displayNameTextSource?.setPosition({y: 92});
			this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.sharingScreen);
		} else {
			// Currently, this dispatch works in all browsers except for firefox
			// this.screenSource.stream.getVideoTracks()[0].dispatchEvent(new CustomEvent('ended'));
			// Using this extracted handler instead
			await this.onScreenCaptureEnd();
		}

		this.applyDefaultRecordingSettings();
		this.handleSilhouetteSource();
	}

	public async updateResolution (notifyDestinations = false) {
		const resolution = this.maxResolution();

		this.scene.setResolution(resolution);
		this.aspectRatio = this.scene.getAspectRatio();
		this.$scope.$evalAsync();

		this.setCameraResolution(resolution);

		if (notifyDestinations) {
			await this.updateScreenResolution(resolution);
		}
	}

	// UI CONTROLS
	public mirrorVideo (): void {
		this.isMirrored = !this.isMirrored;
		this.$window.localStorage.setItem(RECORDING_IS_MIRRORED, String(this.isMirrored));
	}

	public hideMirrorVideoButton (): boolean {
		return this.options.isMultiCam
			|| !!this.getScreenSource()
			|| this.getCameraSources().length > 1;
	}

	// UI LABELS
	public recordButtonLabel (): string {
		if (this.timer.getTime() > 0 && pausedStates.includes(this.state)) {
			return this.options.stateLabels.paused;
		}

		return this.options.stateLabels[this.state];
	}

	public shouldShowVolumeIndicator (): boolean {
		if (!this.hideControls){
			const containerWidth = this.$document[0].querySelector<HTMLElement>('.recorder-main').offsetWidth;
			return containerWidth > 525 && !this.UADetect.browserDetector.isMobileSafari();
		}
	}

	public warnNoScreenCaptureIfFirefoxSafari (): void {
		// If we aren't displaying the button (Mobile/Equipment check/Audio only)
		// Then don't warn them about something they can't do
		if (this.hideControls || this.options.audioOnly || this.UADetect.isMobile()) {
			return;
		}

		if (!BrowserFeature.screenCapture() ||
			this.UADetect.browserDetector.isSafari() ||
			this.UADetect.browserDetector.isFirefox()
		) {
			this.messageRememberingModal.open({
				rememberKey: 'screen-capture-browser-not-supported-seen',
				modalData: {
					title: 'video-scenes_screen-share-unavailable_title',
					message: 'video-scenes_screen-share-unavailable_message'
				}
			});
		}
	}

	public shouldShowScreenCapture (): boolean {
		return !this.options.audioOnly
			&& BrowserFeature.screenCapture()
			&& !this.UADetect.isMobile()
			&& !this.UADetect.browserDetector.isSafari()
			&& !this.UADetect.browserDetector.isFirefox();
	}

	public shouldShowCaptionsButton (): boolean {
		// Show if we are in group-recording scenario
		// with feature flags on for captioning.
		return this.heyGenEnabled() || this.options.showCaptionButton;
	}

	public shouldShowOptionsMenu (): boolean {
		if (!this.hideControls){
			const containerWidth = this.$document[0].querySelector<HTMLElement>('.recorder-main').offsetWidth;

			if (this.canRestart()) {
				return containerWidth <= 675;
			}

			if (this.timer.getTime() > 0) {
				return containerWidth <= 630;
			}

			return containerWidth <= 550;
		}
	}

	public shouldShowBackgroundBlurButton (): boolean {
		return !this.options.audioOnly
			&& (this.UADetect.browserDetector.isChrome()
			|| this.UADetect.browserDetector.isChromiumEdge());
	}

	public shouldDisableBackgroundBlurButton (): boolean {
		return !this.isReady();
	}

	public getBackgroundBlurButtonClass (): string {
		const camera = this.getCameraSource() as CameraSource;
		switch (camera.segmentationState) {
			case SegmentationStates.ACTIVE: return 'ficon-app-background-blur-on';
			case SegmentationStates.INITIALIZING: return 'ficon-spinner';
			default: return 'ficon-app-background-blur-off';
		}
	}

	public isBackgroundBlurOn (): boolean {
		const camera = this.getCameraSource() as CameraSource;
		const onStates = [
			SegmentationStates.ACTIVE,
			SegmentationStates.INITIALIZING
		];
		return onStates.includes(camera.segmentationState);
	}

	public async toggleBackgroundBlur (): Promise<void> {
		const camera = this.getCameraSource() as CameraSource;
		await camera.toggleBlur();
		const state = camera.segmentationState;
		const active = state === SegmentationStates.ACTIVE || state === SegmentationStates.INITIALIZING;
		this.$window.localStorage.setItem(RECORDING_BLUR_START_ACTIVE_KEY, String(active));
		this.$scope.$digest();
	}

	public isScreenCaptureButtonDisabled (): boolean {
		return  !this.isReady()
			|| !this.getScreenSource()?.active && !this.canAddMediaSource();
	}

	public isToggleCameraButtonDisabled (): boolean {
		return !this.isReady()
			|| !this.getCameraSource()
			|| this.inDisabledState(this.getCameraSource().state);
	}

	public isToggleMuteButtonDisabled (): boolean {
		return !this.isReady()
			|| !this.getMicrophoneSource()
			|| this.inDisabledState(this.getMicrophoneSource()?.state);
	}

	public getCameraSource () {
		return this.scene.sources.slice().reverse().find((source) => source instanceof CameraSource) as CameraSource;
	}

	public getCameraSources () {
		return this.scene.sources.filter((source) => source instanceof CameraSource).reverse() as CameraSource[];
	}

	public getMicrophoneSource () {
		return this.scene.sources.find((source) => source instanceof MicrophoneSource) as MicrophoneSource;
	}

	public getMicrophoneSources () {
		return this.scene.sources.filter((source) => source instanceof MicrophoneSource) as MicrophoneSource[];
	}

	public getScreenSource (): ScreenSource {
		return this.scene.sources.find((source) => source instanceof ScreenSource) as ScreenSource;
	}

	public getMediaSources () {
		return this.scene.sources.filter((source) => {
			if (this.heyGenEnabled()) {
				return source instanceof CameraSource ||
					source instanceof ScreenSource ||
					source instanceof MediaStreamSource;
			} else {
				return source instanceof CameraSource ||
					source instanceof ScreenSource;
			}
		}).reverse();
	}

	public hasActiveVideoSource (): boolean {
		return this.scene.sources.some((source) => {
			return source.active
				&& (source instanceof CameraSource || source instanceof ScreenSource);
		});
	}

	public handleSilhouetteSource (): void {
		const shouldShowSilhouette = !this.hasActiveVideoSource() && !this.options.audioOnly;
		this.silhouetteSource?.setActive(shouldShowSilhouette);
	}

	public getMicrophoneTooltipText (): string  {
		const key = this.getMicrophoneSource()?.active ? 'microphone-mute' : 'microphone-unmute';
		return this.translateFilter(key);
	}

	public getCameraEnabledTooltipText (): string {
		const key = this.getCameraSource()?.active ? 'camera-disable' : 'camera-enable';
		return this.translateFilter(key);
	}

	public getScreenCaptureTooltipText (): string  {
		const key = this.getScreenSource()  && this.isReady() ? 'screen-capture-disable' : 'screen-capture-enable';
		return this.translateFilter(key);
	}

	public getScreenCaptureDisabledTooltipText (): string {
		const key = 'video-scenes_screen-share-unavailable_remove_camera_message';
		return this.translateFilter(key);
	}

	public getCaptionTooltipText (): string {
		const key: string = this.captionsActive ? 'captions-turn-off' : 'captions-turn-on';
		return this.translateFilter(key);
	}

	public inDisabledState (state: States): boolean {
		return disabledStates.includes(state);
	}

	public toggleCaptions () {
		this.captionsActive = !this.captionsActive;
		const opentokDestination = this.opentokDestination;

		if (this.captionsActive && opentokDestination) {
			this.captionsStream = opentokDestination.getAudioStream().clone();
		} else if (this.captionsStream) {
			this.captionsStream.getTracks().forEach((track) => track.stop());
			this.captionsStream = null;
		}
	}

	public async onCameraChange (index) {
		// If background blur is on, turn it off and back on here
		const activeSettings = await this.toggleCameraSettingsForCameraChange();
		const defaultCameraSourceState = this.getCameraSource().active;
		const cameraSource = this.getCameraSources()[index];
		cameraSource.resource = this.selectedCameraIds[index];
		await cameraSource.init();
		cameraSource.setActive(defaultCameraSourceState);
		await this.toggleCameraSettingsForCameraChange(activeSettings);
		this.$scope.$digest();
	}

	public async onMicrophoneChange () {
		const microphoneSource = this.getMicrophoneSource();
		microphoneSource.resource = this.selectedMicrophoneId;
		await microphoneSource.init();
		this.scene.updateAudioTrack(microphoneSource as MediaStreamSource);
		this.$scope.$digest();

		const track = microphoneSource.stream.getAudioTracks()[0];
		this.destinations.forEach((destination) => destination.setAudioSource(track));
	}

	public getDuration () {
		return this.timer.getTime();
	}

	public getRecordingStoppedState (): RecordingStoppedState {
		return this.recordingStoppedState;
	}

	public isInstantPlaybackAvailable (): boolean {
		const OTDest = this.destinations.find((dest) => dest instanceof OpentokDestination) as OpentokDestination;
		return OTDest ? OTDest.isInstantPlaybackAvailable() : true;
	}

	public isReady (): boolean {
		return !this.disabled
			&& this.statusOverlay == null
			&& !this.inDisabledState(this.state)
			&& this.isEquipmentReady()
			&& this.destinations.every((d) => {
				return !disabledDestinationStates.includes(d.state);
			});
	}

	public isEquipmentReady (): boolean {
		if (this.options.audioOnly) {
			return this.getMicrophoneSource() && !this.inDisabledState(this.getMicrophoneSource().state);
		}

		if (this.continueWithoutAudio()) {
			return this.getCameraSource() && !this.inDisabledState(this.getCameraSource().state);
		}

		return this.getCameraSource() && !this.inDisabledState(this.getCameraSource().state)
			&& this.getMicrophoneSource() && !this.inDisabledState(this.getMicrophoneSource().state);
	}

	// Keep this in sync with `isEquipmentReady`
	public async waitForEquipmentToBeReady (): Promise<any> {

		if (this.options.audioOnly) {
			return await this.getMicrophoneSource().whenState(States.ACTIVE, this.options.connectionTimeout);
		}

		if (this.continueWithoutAudio()) {
			return await this.getCameraSource().whenState(States.ACTIVE, this.options.connectionTimeout);
		}

		return await Promise.all([
			this.getMicrophoneSource().whenState(States.ACTIVE, this.options.connectionTimeout),
			this.getCameraSource().whenState(States.ACTIVE, this.options.connectionTimeout)
		]);
	}

	public toggleMicrophoneActive (active: boolean): void {
		this.destinations.forEach((destination) => destination.publishAudio(active));
		this.setMicrophoneStatus(active);
		this.$window.localStorage.setItem(RECORDING_MIC_START_ACTIVE_KEY, String(active));
		const key = active ? 'microphone-unmuted' : 'microphone-muted';
		this.$mdLiveAnnouncer.announce(this.translateFilter(key));

		if (active) {
			this.showMuteWarning = false;
		}

		this.muteIndicator?.setActive(!active);
	}

	public toggleCameraActive (cameraActive: boolean): void {
		this.setCameraStatus(cameraActive);
		this.$window.localStorage.setItem(RECORDING_CAM_START_ACTIVE_KEY, String(cameraActive));
		const key = cameraActive ? 'camera-enabled' : 'camera-disabled';
		this.$mdLiveAnnouncer.announce(this.translateFilter(key));
		this.determineMediaSourcesLayout(cameraActive);
		this.handleSilhouetteSource();
	}

	public closeMenus = (evt: MouseEvent) => {
		const target = evt.target as HTMLElement;
		//don't close if the button is clicked, instead just let it toggle
		if (!target.closest('.options-menu-btn') && this.menuActive) {
			this.$scope.$apply(() => this.menuActive = false);
		}
		if (target.closest('.close') || (!target.closest('.options-menu') && !target.closest('.media-settings-btn') && this.mediaSettingsActive)) {
			this.$scope.$apply(() => this.mediaSettingsActive = false);
		}
	};

	public toggleOptionsMenu () {
		this.menuActive = !this.menuActive;
	}

	public toggleMediaSettings () {
		this.mediaSettingsActive = !this.mediaSettingsActive;

		setTimeout(() => {
			if (this.mediaSettingsActive) {
				this.setTabTrapOnMediaSettings();
			} else {
				this.settingsButtonFocus();
				this.mediaSettingsTabGroup = null;
			}
		});
	}

	public setTabTrapOnMediaSettings () {
		const mediaSettingsElem = this.$element[0].querySelector<HTMLElement>('.media-settings');
		this.mediaSettingsTabGroup = this.TrapTabIndex.start({
			targetElement: mediaSettingsElem,
			autoFocusFirstElement: true,
			trapTabEvent: true
		} as TrapIndexConfig);
	}

	public settingsButtonFocus () {
		setTimeout(() => {
			const settingsButton = this.$element[0].querySelector<HTMLElement>('.media-settings-btn');
			if (settingsButton) {
				settingsButton.focus();
			}
		});
	}

	public determineMediaSourcesLayout (cameraActive: boolean): void {
		const mediaSources = this.getMediaSources();
		const screenSource = this.getScreenSource();
		if (screenSource && !cameraActive) {
			screenSource.setPosition(GoSource.POSITIONS.FULL);
		} else {
			if (mediaSources.length === 1) {
				mediaSources[0].setPosition(GoSource.POSITIONS.FULL);
			} else if (mediaSources.length === 2) {
				if (screenSource) {
					mediaSources[0].setPosition(GoSource.POSITIONS.ONETHIRD);
					mediaSources[1].setPosition(GoSource.POSITIONS.TWOTHIRD);
					this.fullstoryService.createEvent(FULLSTORY_EVENTS.SCREENSHARE_66_33_LAYOUT, {});
				} else {
					mediaSources[0].setPosition(GoSource.POSITIONS.LEFTHALF);
					mediaSources[1].setPosition(GoSource.POSITIONS.RIGHTHALF);
				}
			}
			else if (mediaSources.length === 3) {
				mediaSources[0].setPosition(GoSource.POSITIONS.TOPHALFCENTERED);
				mediaSources[1].setPosition(GoSource.POSITIONS.BOTTOMLEFTHALF);
				mediaSources[2].setPosition(GoSource.POSITIONS.BOTTOMRIGHTHALF);
			}
			else if (mediaSources.length === 4) {
				mediaSources[0].setPosition(GoSource.POSITIONS.TOPLEFTHALF);
				mediaSources[1].setPosition(GoSource.POSITIONS.TOPRIGHTHALF);
				mediaSources[2].setPosition(GoSource.POSITIONS.BOTTOMLEFTHALF);
				mediaSources[3].setPosition(GoSource.POSITIONS.BOTTOMRIGHTHALF);
			}
		}

	}

	public setMicrophoneStatus (active: boolean): void {
		this.getMicrophoneSources().forEach((microphone) => microphone.setActive(active));
		// This is needed to make sure captions don't pick up a muted microhpone
		this.destinations.forEach((destination) => destination.emit(active ? 'microphoneUnmuted' : 'microphoneMuted'));
	}

	public setCameraStatus (active: boolean): void {
		this.getCameraSources().forEach((camera) => camera.setActive(active));
	}

	public setCameraResolution (resolution: VideoQuality) {
		const dimension = resolution.split('x');

		this.getCameraSources()
			.forEach((camera) => {
				if(
					camera.config.width !== +dimension[0] ||
					camera.config.height !== +dimension[1]
				) {
					camera.config.width = +dimension[0];
					camera.config.height = +dimension[1];
					camera.init();
				}
			});
	}

	public screenCaptureInterrupt (): void {
		this.onScreenCaptureEnd(true);
	}

	public setResolutionUpdateProcessingState (
		state: Reasons.SCREEN_SHARE_PREPARING | Reasons.RESOLUTION_UPDATING | null
	): void {

		// If there are no other non-screen-share reason, then update
		if (this.statusOverlay?.reason === Reasons.SCREEN_SHARE_PREPARING ||
			this.statusOverlay?.reason === Reasons.RESOLUTION_UPDATING ||
			this.statusOverlay == null) {

			this.setStatusOverlay(state != null ? new StatusOverlayReason(state) : null);
		}
	};

	public getCameraAngleLabel (index) {
		if (index === 0 && this.getCameraSources().length === 1) {
			return 'video-scenes_media-settings-camera';
		} else {
			return this.translateFilter('video-scenes_media-settings-camera-angle', {number: index + 1});
		}
	}

	public shouldShowAddCameraAngleButton (): boolean {
		return !this.options.isMultiCam
			&& !this.hideControls
			&& !this.UADetect.isMobile()
			&& !this.UADetect.browserDetector.isSafari()
			&& !this.UADetect.browserDetector.isFirefox()
			&& !this.options.audioOnly
			&& this.canAddMediaSource();
	}

	public canAddMediaSource (): boolean {
		return this.getMediaSources().length < 4;
	}

	public async addCameraAngle (event?: Event) {
		this.isMirrored = false;
		event?.stopPropagation();
		const dimension = this.maxResolution().split('x');
		const cameraSource = this.createSource(new CameraSource({
			width: +dimension[0], height: +dimension[1],
			...this.sourceConfig
		}, this.deviceKit)) as CameraSource;
		const textSource = new TextSource({
			position: {x: 0, y: 0, halign: 'left', valign: 'top'},
			backgroundColor: 'rgba(0,0,0,0.75)',
			padding: 2,
			fontSize: 3
		});
		cameraSource.subSources.push(textSource);
		await cameraSource.init();
		const defaultCameraSource = this.getCameraSource();

		this.selectedCameraIds.push(cameraSource.resource);
		this.updateCameraLabels();
		if (cameraSource !== defaultCameraSource) {
			cameraSource.setActive(defaultCameraSource.active);
			this.updateResolution();
			this.setTabTrapOnMediaSettings();

			// For vpat tabbing accessibility
			if (this.canAddMediaSource()) {
				this.setFocusToAddCameraAngleButton();
			}
		}

		this.determineMediaSourcesLayout(defaultCameraSource.active);

		return cameraSource;
	}

	public removeCameraAngle (event, index) {
		if (!this.isReady()) return;
		event.stopPropagation();
		const cameraSource = this.getCameraSources()[index];
		this.scene.removeSource(cameraSource);
		this.selectedCameraIds.splice(index, 1);
		this.updateCameraLabels();
		this.updateResolution();
		this.determineMediaSourcesLayout(this.getCameraSource().active);
		this.setTabTrapOnMediaSettings();

		// For vpat tabbing accessibility
		this.setFocusToAddCameraAngleButton();
	}

	public setFocusToAddCameraAngleButton () {
		const interval = this.$interval(() => {
			if (this.isReady()) {
				const addCameraBtn = this.$element[0].querySelector<HTMLElement>('.add-camera-btn');
				addCameraBtn.focus();
				this.$interval.cancel(interval);
			}
		}, 300);
	}

	/**
	 * On escape listerner for media settings icons action.
	 *
	 * @returns void
	 */
	public escapeListener (event: KeyboardEvent): void {
		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.mediaSettingsActive = false;
				this.menuActive = false;
				this.settingsButtonFocus();
			});
		}
	}

	public shouldAdjustLayout (): boolean {
		if (!this.hideControls){
			const containerWidth = this.$document[0].querySelector<HTMLElement>('.recorder-main').offsetWidth;
			return containerWidth < 420;
		}
	}

	public canRestart (): boolean {
		const activity = this.selectedService.getActivity();
		return this.umcInstanceName === 'response-media' && activity.allowsPractice() && this.timer.getTime() > 0;
	}

	public restartSession () {
		this.ngxDowngradeModalService.openConfirmDialog({
			title: this.$translate.instant('modal-confirm-restart-session_title'),
			message: this.$translate.instant('modal-confirm-restart-session_message'),
			confirmText: this.$translate.instant('common_restart')
		}).then(() => {
			this.setStatusOverlay(new StatusOverlayReason(Reasons.RESTARTING_SESSION));
			const session = this.selectedService.getSession();
			session.restart()
				.then(() => {
					this.$window.sessionStorage.setItem(SESSION_RESTART, 'true');
					this.$window.location.reload();
				})
				.catch(() => {
					this.setStatusOverlay(null);
					this.ngxGoToastService.createToast({
						type: GoToastStatusType.ERROR,
						message: 'session-restart_failed'
					});
				});
		}).catch(() => noop);
	}

	private updateCameraLabels () {
		const cameras = this.getCameraSources();
		const enable = cameras.length > 1;
		cameras.forEach((source, index) => {
			const textSource = source.subSources.find((subSource) => subSource instanceof TextSource);
			if (!textSource) return;
			textSource.resource = `Camera ${index+ 1}`;
			textSource.setActive(enable);
		});
	}

	public maxResolution () {
		const resolutions = [VideoQualityUtil.MINIMUM_RESOLUTION];
		const opentokDestination = this.opentokDestination;

		if (opentokDestination && (opentokDestination.hasUsedScreenCapture || opentokDestination.screenShareActive)) {
			resolutions.push(VideoQualityUtil.SCREEN_CAPTURE_MINIMUM_RESOLUTION);
		}

		if (this.options.videoQuality) {
			resolutions.push(this.options.videoQuality);
		}

		return VideoQualityUtil.max(resolutions);
	}

	private checkUnsupportedBrowser (): void {
		let error: StatusOverlayReason;
		if (this.featureSupport.isIOSBrowserNonSafari()) {
			error = new StatusOverlayReason(Reasons.IOS_NON_SAFARI_BROWSER, this.featureSupport.browser.name);
		} else if (!this.featureSupport.areBrowserRequirementsMetForRecording()) {
			error = new StatusOverlayReason(Reasons.UNSUPPORTED_BROWSER, this.featureSupport.browser.name);
		}
		if (error) this.setStatusOverlay(error);
	}

	// INITIAL SETUP
	private async setup (): Promise<void> {
		this.disabled = true;
		try {
			// Create canvas container and append
			const element: HTMLDivElement = this.$window.document.createElement('div');
			element.classList.add('GR_publisher');
			element.appendChild(this.scene.canvasEle);
			this.outputContainer.appendChild(element);
			this.scene.outputContainer = this.outputContainer;
			this.scene.overlayNameEnabled = this.options?.overlayNameEnabled;
			// Load the OpenTok Destination
			// The destinations array needs to be populated with destinations
			// in an inactive state so Equipment Check's record button is not
			// enabled between the time when all hardware is good and destinations
			// begin connecting.
			if (this.options.opentok) this.loadOpentokDestination();
			if (this.options.mediaRecorder) this.loadMediaRecorderDestination();

			await this.setupSourcesAndDestinations();

			this.applyDefaultRecordingSettings();
		} catch (error) {
			this.$log.error(error);
			this.setState(States.INACTIVE);
		}
	}

	private async setupSourcesAndDestinations () {
		if (this.options.isMultiCam && !this.muteIndicator) {
			this.muteIndicator = new ImageSource({
				resource: 'https://staticassets.goreact.com/video-scene-mic-muted.svg',
				position: MUTE_INDICATOR_POSITIONS.default
			});
			await this.muteIndicator.init();
			this.scene.addSource(this.muteIndicator);
			this.muteIndicator.setActive(!this.startRecordingMicActive());
		}

		// Create Display Name
		if (this.options.displayName && !this.displayNameTextSource) {
			try {
				const response = await this.authService.getCurrentUser();
				this.displayNameTextSource = new TextSource({
					resource: `${response.data.first_name} ${response.data.last_name}`,
					position: {x: 100, y: 100, halign: 'right', valign: 'bottom'},
					backgroundColor: 'rgba(0,0,0,0.75)',
					padding: 2,
					fontSize: 3
				});
				this.displayNameTextSource.setActive(true);
				this.scene.addSource(this.displayNameTextSource);
			} catch (error) {
				this.$log.error(error);
			}
		}

		await this.deviceKit.ensureDevicePermissions(!this.options.audioOnly, true);

		// Create Camera Source
		if (!this.options.audioOnly && !this.getCameraSource()) {
			const cameraSource = await this.addCameraAngle();

			if (cameraSource.state !== States.MISSING) {
				const videoTrack = cameraSource.stream.getVideoTracks()[0];
				videoTrack.enabled = this.startRecordingCamActive();
			}

			if (this.startRecordingBlurActive()) {
				await (cameraSource as CameraSource).toggleBlur();
			}
			this.isMirrored = this.startRecordingMirrored();
		}

		// Create Microphone Source
		if (!this.getMicrophoneSource()) {
			const options = this.continueWithoutAudio()
				? { continueWithoutAudio: true }
				: null;
			const microphoneSource = this.createSource(new MicrophoneSource(this.sourceConfig, this.deviceKit));
			await microphoneSource.init(options);
			this.selectedMicrophoneId = microphoneSource.resource;
			if (microphoneSource.state !== States.MISSING) {
				const audioTrack = microphoneSource.stream.getAudioTracks()[0];
				audioTrack.enabled = this.startRecordingMicActive();
			}
		}

		if(!this.silhouetteSource) {
			this.silhouetteSource = new ImageSource({
				resource: 'https://staticassets.goreact.com/video-scene-updated-camera-muted-icon.svg' ,
				position: {
					x: 50, y: 50, valign: 'middle', halign: 'center',
					resourceWidth: 400, resourceHeight: 250
				}
			});
			await this.silhouetteSource.init();
			this.scene.addSource(this.silhouetteSource);
			this.handleSilhouetteSource();
		}

		// Populate device lists
		if (!this.options.audioOnly) this.cameras = await this.deviceKit.getDevicesByKind('video');
		this.microphones = await this.deviceKit.getDevicesByKind('audio');

		await this.waitForEquipmentToBeReady();
		await this.initDestinations();

		// Activate hot swapping between canvas and direct output for browsers that pause rendering while hidden
		if (this.UADetect.browserDetector.isFirefox() || this.UADetect.browserDetector.isSafari()) {
			this.$window.document.addEventListener('visibilitychange', this.handleVisibilityChange);
		}

		this.disabled = false;
		this.setState(States.ACTIVE);
	}

	private handleVisibilityChange = () => {
		let track;

		if (this.$window.document.hidden) {
			const cameraSource = this.getCameraSource();
			track = cameraSource?.stream.getVideoTracks()[0];

			if (!cameraSource?.active) {
				track = this.scene.getStream().getVideoTracks()[0];
			}
		} else {
			track = this.scene.getStream().getVideoTracks()[0];
		}

		if (!track) return;
		this.destinations.forEach((destination) => destination.setVideoSource(track));
	};

	private onScreenCaptureStart = () => {
		if (this.isRecording) {
			this.opentokDestination.hasUsedScreenCapture = true;
		}

		this.opentokDestination.screenShareActive = true;
		this.updateResolution(true);
		// Notify on screen capture start
		return Promise.all(this.destinations.map((destination) => {
			return destination.screenCaptureStart();
		}));
	};

	private onScreenCaptureEnd = (interrupted: boolean | Event = false) => {
		const screenSource = this.getScreenSource();

		if (screenSource == null) {
			// We've already turned it off, so do nothing
			return;
		}

		if (interrupted !== true) {
			this.opentokDestination.screenShareActive = false;
			this.opentokDestination.myScreenShareIsActive = false;
		}

		this.displayNameTextSource?.setPosition({y: 100});
		this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.default);
		screenSource.off(GoSource.EVENTS.STATE_CHANGE, this.sourceStateChange);
		const track = screenSource.stream.getVideoTracks()[0];
		track.removeEventListener('ended', this.onScreenCaptureEnd);
		this.scene.removeSource(screenSource);
		this.updateResolution(true);
		this.$scope.$evalAsync();
		this.determineMediaSourcesLayout(this.getCameraSource().active);
		this.trackScreenCaptureEvent('exit-screen-capture');
		this.$mdLiveAnnouncer.announce(this.translateFilter('screen-capture-disabled'));
	};

	private updateScreenResolution = (resolution: OT.GetUserMediaProperties['resolution']) => {
		// Notify on resolution update
		return Promise.all(this.destinations.map((destination) => {
			return destination.updateScreenResolution(resolution);
		}));
	};

	// SCENE ITEMS
	private createSource<T extends GoSource> (source: T): T {
		this.scene.addSource(source);
		source.on(GoSource.EVENTS.STATE_CHANGE, this.sourceStateChange);
		return source;
	}

	// DESTINATIONS
	private loadOpentokDestination (): void {
		const opentokDestination = new OpentokDestination({
			...this.options.opentok,
			type: DestinationType.OPENTOK,
			videoScene: this,
			containerElement: this.outputContainer,
			params: this.options.params,
			goReactToken: this.options.goReactToken,
			timer: this.timer,
			displayName: this.options.displayName,
			overlayNameEnabled: this.options?.overlayNameEnabled,
			isMultiCam: this.options.isMultiCam,
			opentokHack: this.options.opentokHack
		});
		this.destinations.push(opentokDestination);
		this.opentokDestination.on(Destination.EVENTS.STATE_CHANGE, this.destinationStateChange);
		this.opentokDestination.on(SUBSCRIBER_CHANGE, this.handleSubscriberChange);
		this.opentokDestination.hasUsedScreenCapture = this.options.opentok?.hasUsedScreenCapture ?? false;
		this.updateResolution();
	}

	private loadMediaRecorderDestination (): void {
		const destination = new MediaRecorderDestination(this.options, this.uploadManager, this.UADetect);
		this.destinations.push(destination);
		destination.on(Destination.EVENTS.STATE_CHANGE, this.destinationStateChange);
		destination.on(SUBSCRIBER_CHANGE, this.handleSubscriberChange);
	}

	private async initDestinations (): Promise<void> {
		const promises = [];
		this.destinations.forEach(async (destination) => {
			if(destination.state === GoSource.STATES.UNINITIALIZED && this.isEquipmentReady()) {
				promises.push(destination.init(this.scene.getStream()));
			}
		});
		await Promise.all(promises);
	}

	// This is the correct location to do things such as default muting the mic
	private applyDefaultRecordingSettings (): void {
		if (this.getCameraSource() && this.getCameraSource().state !== States.MISSING) {
			const active = this.startRecordingCamActive();
			this.setCameraStatus(active);
			this.handleSilhouetteSource();
		}

		if (this.getMicrophoneSource().state !== States.MISSING) {
			const active = this.startRecordingMicActive();
			this.setMicrophoneStatus(active);
		}

		if (!this.getMicrophoneSource().active && !this.hideControls) {
			// Show toast warning you are inactive but only if controls aren't hidden
			this.showMuteWarning = true;
			this.$timeout(() => this.showMuteWarning = false, 5000);
		}
	}

	private handleSubscriberChange = (subscriberCount: number) => {
		this.subscriberCount = subscriberCount;

		if (!this.displayNameTextSource && !this.muteIndicator) return;
		const screenShareLayoutEnabled = this.outputContainer?.classList.contains('screen-share-layout');

		if (this.getScreenSource()?.active) {
			this.displayNameTextSource?.update({ fontSize: 3 });
			this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.sharingScreen);
		} else if (screenShareLayoutEnabled) {
			this.displayNameTextSource?.update({ fontSize: 12 });
			this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.observingScreenShare);
		} else {
			if (subscriberCount >= 5) {
				this.displayNameTextSource?.update({ fontSize: 7 });
				this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.moreThanFourParticipants);
			} else {
				if (subscriberCount >= 2) {
					this.displayNameTextSource?.update({ fontSize: 5 });
				} else {
					this.displayNameTextSource?.update({ fontSize: 3 });
				}
				this.muteIndicator?.setPosition(MUTE_INDICATOR_POSITIONS.default);
			}
		}
	};

	// EVENTS
	// This has to be an arrow function or else the setup listener throws
	private handleStateChange = (state: States): void => {
		this.$scope.$evalAsync(() => {
			switch (state) {
				case States.RECORDING:
					this.timer.start();
					break;
				default:
					this.timer.pause();
					break;
			}

			this.isRecording = state === States.RECORDING;
		});
	};

	private sourceStateChange = (_state: States, source: GoSource): Promise<void> => {
		// Clear out the statusOverlay if it has been resolved
		if (source === this.statusOverlay && source.state !== GoSource.STATES.FAILED) {
			this.setStatusOverlay(null);
		}

		// We are currently unable to connect to opentok until all hardware is good.
		// Once we switch to a setup where go-scene always outputs an audio track that
		// it then combines with other audio tracks, we can stop calling initOpentokDestination.
		let promise = Promise.resolve();
		switch (source.state) {

			// In the case a user has agreed to not
			// use one of their devices, we need to finish
			// setting up sources and destinations
			case GoSource.STATES.MISSING:
				promise = this.setupSourcesAndDestinations();
				break;
			// Handle scenarios such as cancelling screen capture where we should not show an error
			case GoSource.STATES.DESTROYED:
				this.scene.removeSource(source);
				break;
			case GoSource.STATES.FAILED:
				this.setStatusOverlay(source);
				break;
			case GoSource.STATES.ACTIVE:
				this.handleSilhouetteSource();
				break;
		}

		return promise.then(() => {
			// Emit publicly individual source states
			// this is for EquipmentCheck
			this.$scope.$evalAsync(() => {
				const event = source instanceof CameraSource || source instanceof MicrophoneSource ?
					source.eventName :
					'';
				if (event) this.emit(event, source.state);
			});
		});
	};

	private destinationStateChange = (_state: States, destination: Destination): void => {
		// Clear out the statusOverlay if it has been resolved
		this.$scope.$evalAsync(() => {
			if ((destination === this.statusOverlay && destination.state !== GoSource.STATES.FAILED) ||
				(this.statusOverlay?.reason === Reasons.OPENTOK_CONNECTING)) {
				this.setStatusOverlay(null);
			}

			switch(destination.state) {
				case Destination.STATES.INITIALIZING:
					// We don't want to overwrite if there's an error message instead
					if (this.statusOverlay == null) {
						this.setStatusOverlay(new StatusOverlayReason(Reasons.OPENTOK_CONNECTING));
					}
					break;
				case Destination.STATES.ACTIVE:
					// When opentok connection fails and re-initializes
					// We should set controller state to its previous state
					if (this.state === States.STARTING) {
						if (this.timer.getTime() > 0) {
							this.setState(States.PAUSED);
						} else {
							this.setState(States.ACTIVE);
						}
					}

					const opentokDestination = this.opentokDestination;

					// set initial connection status
					opentokDestination.remoteStreamStats(0)
						.then((response: OT.PublisherStats) => {
							this.packetsLost = response.video.packetsLost;
							this.packetsSent = response.video.packetsSent;
						});

					// clear interval when there are state changes
					// as losing connection and then getting back to active causes multiple intervals running
					this.$interval.cancel(this.connectionInterval);

					// watch connection status on 5 second interval
					this.connectionInterval = this.$interval(() => {
						opentokDestination.remoteStreamStats(0)
							.then((response: OT.PublisherStats) => {
								this.checkAndTrackConnection(response);
							});
					}, 5000);

					break;
				case Destination.STATES.RECORDING:
					this.setState(States.RECORDING);
					break;
				case Destination.STATES.STOPPED:
					this.setState(States.STOPPED);
					break;
				case Destination.STATES.PAUSED:
					this.setState(States.PAUSED);
					break;
				case Destination.STATES.FAILED:
					this.setStatusOverlay(destination);
					break;
			}

			// Emit publicly individual destination states
			// this is for EquipmentCheck
			const event = destination.eventName;
			if (event) this.emit(event, destination.state);
		});
	};

	private checkAndTrackConnection (stats: OT.PublisherStats) {
		const packetsLostDifference = stats.video.packetsLost - this.packetsLost;
		const packetsSentDifference = stats.video.packetsSent - this.packetsSent;

		let goodConnection = true;
		if (packetsSentDifference <= 0) {
			goodConnection = false;
		} else {
			goodConnection = (packetsLostDifference / packetsSentDifference) < 0.04;
		}
		this.onConnectionStatus({goodConnection});

		// keep track of new values for next interval calculation
		this.packetsLost = stats.video.packetsLost;
		this.packetsSent = stats.video.packetsSent;
	}

	private timeSync = (time: number): void => {
		// Recording is happening, trigger heartbeat so that the
		// sessionManager service knows the user is active.
		this.GoGlobalEvent.trigger(this.GoReactHeartbeatEvent);

		// Check to see if recording time has exceeded the
		// time limit, if so, immediately stop recording.
		if (this.isTimeLimitExceeded(time)) {
			this.stop();
		}
	};

	private isTimeLimitExceeded (time: number): boolean {
		return this.options.timeLimit && time >= this.options.timeLimit * 60 * 1000;
	}

	private setStatusOverlay (status: StatusOverlayable) {
		this.$scope.$evalAsync(() => this.statusOverlay = status);
	}

	private startRecordingMicActive () {
		return this.$window.localStorage.getItem(RECORDING_MIC_START_ACTIVE_KEY) !== 'false';
	}

	private startRecordingCamActive () {
		return this.$window.localStorage.getItem(RECORDING_CAM_START_ACTIVE_KEY) !== 'false';
	}

	private startRecordingBlurActive () {
		return this.$window.localStorage.getItem(RECORDING_BLUR_START_ACTIVE_KEY) === 'true';
	}

	private startRecordingMirrored () {
		if (this.hideMirrorVideoButton()) {
			return false;
		} else if (this.$window.localStorage.getItem(RECORDING_IS_MIRRORED)) {
			return this.$window.localStorage.getItem(RECORDING_IS_MIRRORED) === 'true';
		} else {
			return true;
		}
	}

	private continueWithoutAudio () {
		return !!this.$window.localStorage.getItem(CONTINUE_WITHOUT_AUDIO_KEY);
	}

	private initUnsavedDataRegister () {
		this.unsavedDataRegistration = {
			isInUnsavedState: () => [States.RECORDING, States.PAUSED].includes(this.state),
			resolve: () => {
				return this.$q((resolve, reject) => {
					this.messageModal.open({
						modalData: {
							message: 'go-recorder_warn-on-exit-message',
							title: 'go-recorder_warn-on-exit-title',
							resolveBtnClass: 'primary-btn',
							resolveBtnText: 'go-recorder_warn-on-exit-resolve-btn',
							rejectBtnClass: 'tertiary-btn',
							rejectBtnText: 'go-recorder_warn-on-exit-reject-btn'
						}
					}).result
						.then(reject)
						.catch(resolve);
				});
			}
		};
		this.ngxUnsavedDataManagerService.register(this.unsavedDataRegistration);
	}

	// Keep the page from sleeping - A chromium tab will begin
	// power saving if the users microphone is not enabled or
	// there is no audio being played and the tab is not in focus.
	// https://www.tenforums.com/tutorials/80233-enable-disable-google-chrome-background-tab-throttling-windows.html
	private keepAlive () {
		this.keepAliveEle = this.$window.document.createElement('audio');
		this.keepAliveEle.src = silentAudioFile;
		this.keepAliveEle.loop = true;
		this.keepAliveEle.autoplay = true;
		this.keepAliveEle.volume = 0.5;
	}

	// ANALYTICS
	private trackScreenCaptureEvent (eventName: string): void {
		this.$analytics.eventTrack(eventName, {
			category: 'screen-capture',
			label: 'VideoScene Screen Capture'
		});
	}

	private async toggleCameraSettingsForCameraChange (activeSettings: { blurEnabled?: boolean } = {}) {
		activeSettings.blurEnabled = this.isBackgroundBlurOn() || activeSettings.blurEnabled;

		if (activeSettings.blurEnabled) {
			await this.toggleBackgroundBlur();
		}

		return activeSettings;
	}

	private get opentokDestination (): OpentokDestination {
		return this.destinations.find((dest) => dest instanceof OpentokDestination) as OpentokDestination;
	}

	public toggleHeygenEcho () {
		// unpausing has to happen before setup... really weird
		this.heygenEchoing = !this.heygenEchoing;
		this.speechToText.setPaused(!this.heygenEchoing);

		if (!this.speechToTextInitialized) {
			this.speechToTextInitialized = true;
			this.heygenEchoing = true;
			this.speechToText.startTranscribingStream(this.getMicrophoneSource().stream);
		}

		this.setupHeyGen();
	}

	public toggleHeyGenScenario () {
		if (this.heyGenScenario === 'interview') {
			this.heyGenScenario = 'language';
		} else {
			this.heyGenScenario = 'interview';
		}
	}

	public heyGenEnabled (): boolean {
		return this.featureFlag.isAvailable('HEYGEN');
	}

	private setupHeyGen (): Promise<void> {
		this.interrupted = true;
		this.speechToText.on('data', (data: RealtimeTranscript) => {
			if (data.text === '') {
				return;
			}

			if (!this.interrupted) {
				this.interrupted = true;
				this.avatar.interrupt({ interruptRequest: {
					sessionId: this.avatarData.sessionId
				} });
			}

			if (this.cancelDeferred != null) {
				this.cancelDeferred.resolve();
				this.cancelDeferred = null;
			}

			if (data.message_type === 'FinalTranscript') {
				if (this.heyGenScenario === 'interview') {
					this.conversation += '\nInterviewee: "' + data.text + "'";
				} else {
					this.conversation += '\nLearner: "' + data.text + "'";
				}

				this.cancelDeferred = this.$q.defer();

				this.$http.post<{response: string}>(`/api/v2/assemblyai/task?heyGenScenario=${this.heyGenScenario}`, {
					previous: this.conversation
				}, {
					timeout: this.cancelDeferred.promise
				}).then((response) => {
					this.cancelDeferred.resolve();
					this.cancelDeferred = null;

					this.interrupted = false;
					this.avatar.speak({ taskRequest: {
						text: response.data.response,
						sessionId: this.avatarData.sessionId
					}});

					if (this.heyGenScenario === 'interview') {
						this.conversation += '\nInterviewer: "' + response.data.response + '"';
					} else {
						this.conversation += '\nSpanish Teacher: "' + response.data.response + '"';
					}
				});
			}
		});

		return new Promise((resolve, _reject) => {

			this.$http.get<{token: string}>('/api/v2/heygentoken')
				.then((response) => {
					return response.data.token;
				}).then((token) => {
					this.avatar = new StreamingAvatarApi(
						new Configuration({ accessToken: token })
					);

					return this.$q.when(this.avatar.createStartAvatar({
						newSessionRequest: {
							quality: 'low',
							avatarName: 'josh_lite3_20230714',
							voice: { voiceId: this.heyGenScenario === 'interview' ?
								'd0db938c61c54a2bba197e36df40374f':
								'37f7ef47d2b6498e8781e168979b10df' }
						}
					}));
				}).then((data: NewSessionData) => {
					this.avatarData = data;
					this.sourceConfig.muted = false;
					const src = new MediaStreamSource(this.sourceConfig, this.avatar.mediaStream);
					this.$q.when(src.init()).then(() => {
						this.createSource(src);
						this.destinations.forEach((destination) => {
							destination.setAudioSource(this.scene.combineAudio(this.avatar.mediaStream));
						});
						this.scene.setResolution(VideoQualityUtil.SCREEN_CAPTURE_MINIMUM_RESOLUTION);
						const mediaSources = this.getMediaSources();
						mediaSources[1].setPosition(GoSource.POSITIONS.LEFTHALF);
						mediaSources[0].setPosition(GoSource.POSITIONS.RIGHTHALF);
						resolve();
					});
				});
		});
	}
}
